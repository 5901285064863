<template lang="pug">
<navMenu />
<HeaderPages :title="'Компания'" :text="this.text" />
.advantages 
  .advantages-title Наши преимущества
  .advantages-text {{textAvantages}}
<Services :services='this.services' :isImage='true' :postImages='this.postImages' />
<Footer />
</template>

<script>
import navMenu from "@/components/navmenu.vue";
import HeaderPages from "@/components/headerpages.vue";
import Services from "@/components/services.vue"
import Footer from "@/components/footer.vue";
export default {
  name: "About",
  components: {
    navMenu,
    HeaderPages,
    Services,
    Footer,
  },
  data() {
    return {
      postImages: 'company.png',
      text: `Компания основана в 2003 году.  
        На сегодняшний день приоритетными направлениями  
        для нас являются: Внедрение собственной системы  
        шифрование данных, CMS и CRM-Систем. Собственные  
        сервера компаний и оптоволоконные каналы связи от  
        двух крупнейших операторов России также являются  
        весомым аргументом в пользу выбора нашей компании.  
        Компания имеет лицензию на телематику и входит в  
        реестр обработчиков персональных данных. Система  
        управления сайтом "Скарлетт & Монарх" также занесена  
        в единый реестр российских программ Минкомсвязь.`,
      services: [
        { id: "01", name: "Безопастность", text: `Собственная система обратимого  
                                            шифрования данных. Использование, Let's 
                                            Encrypt при передаче данных. Двухэтапные
                                            механизмы аутентификации.` },
        { id: "02", name: "Удобство", text: `Для работы с системой необходим только 
                                        интернет. GUI системы позволяют получать
                                        доступ к данным максимум в три клика.`},
        { id: "03", name: "Динамика", text: `Постоянное развитие и адаптация продукта 
                                        под существующие рынки. Высокие 
                                        скорости при обработке запросов.` },
        { id: "04", name: "Гибкость", text: `GUI интегрируется для конечного 
                                        пользователя. Работа с любыми типами 
                                        данных (кроме бинарных). Работа с большей 
                                        частью сервисов (Яндекс-таблицы, Google 
                                        Style Sheets, 1C, iiko).`},
        { id: "05", name: "Эффективность", text: `Cнижение операционных расходов
                                            компании, экономия на площади и рабочих 
                                            местах. Снижение ошибок по причине 
                                            "Человеческого фактора".`},
        { id: "06", name: "Доступность", text: `Простота использования и наглядность. 
                                          Наличие интерактивных помощников в 
                                          системе.` },
      ],
      textAvantages: `Интеграция с любым программным обеспечением, от 1С 
        до iiko. В разработку уже включён интернет-эквайринг и 
        собственная система шифрования данных, позволяющая 
        обезопасить транзакции всех уровней.
        Доступ и возможность осуществлять операции в системе 
        со смартфонов и мобильных устройств. Возможность 
        подключать дополнительные рабочие места без 
        дополнительных затрат. Адекватный и современный 
        интерфейс настраиваемый пользователем.`
    };
  },
};
</script>