<template lang="pug">
header.header
  <navMenu :class="{ 'nav-header': 'isHeader'}" />
  .header-content
    .header-content-left
      img.scarlett(src="../assets/icons/scarlett.svg")
      .header-content-left-subtitle Группа компаний
      .header-content-left-title Скарлетт и Монарх
      .header-content-left-text 
        | Компания основана в 2003 году.
        | На сегодняшний день приоритетными направлениями для нас являются: 
        | Внедрение собственной системы шифрования данных, CMS и CRM-Систем. 
        | Собственные сервера компании и оптоволоконные каналы связи от двух крупнейших операторов 
        | России также являются весомым аргументом в пользу выбора нашей компании. 
        | Компания имеет лицензию на телематику и входит в реестр обработчиков персональных данных. 
        | Система управления сайтом "Скарлетт & Монарх" также занесена в единый реестр 
        | российских программ Минкомсвязь.
    .header-content-right
      ul.header-content-right-menu
        li.header-content-right-menu-item(v-for="el in subMenu")
          router-link.header-content-right-menu-item-link(:k="el" :to="`${el.link}`") {{el.name}} 
</template>

<script>
import navMenu from '@/components/navmenu.vue'


export default {
  name: "Header",
  components: {
    navMenu,
  },
  data() {
    return {
      isHeader: true,
      subMenu: [
        { name: "Хостинг/ЦОД", link: "/Hosting" },
        { name: "Веб-сайты", link: "/Cases" },
        { name: "Реклама", link: "/Advertising" },
        { name: "Дизайн-студия", link: "/Design" },
        { name: "CMS и CRM", link: "/CmsAndCrm" },
        { name: "Моб. приложения", link: "/Design" },
      /* { name: "Пресс релиз", link: "/PressReliz" } */
      ],
    };
  },
};
</script>

