<template>
  <navMenu />
  <HeaderPages :title="'Хостинг/ЦОД'" />
  <Services :services='this.services' :hostImg='true' />
  <Prices
    :prices='this.prices'
    :images='this.images'
    :blackBg='true'
  />
  <Footer />
</template>

<script>
import navMenu from "@/components/navmenu.vue";
import HeaderPages from "@/components/headerpages.vue";
import Services from "@/components/services.vue"
import Prices from "@/components/prices.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Hosting",
  components: {
    navMenu,
    HeaderPages,
    Services,
    Prices,
    Footer,
  },
  data() {
    return {
      images: "hosting.png",
      prices: [
        {
          id: "one-red.svg",
          title: "Shared \n Hosting",
          price: "7 000 руб.",
        },
        {
          id: "two-red.svg",
          title: "Выделенное \n окружение",
          price: "120 000 руб.",
        },
      ],
      services: [
        { id: "01", name: "Up-time 99,99%" },
        { id: "02", name: "Бекап три раза \n в неделю" },
        { id: "03", name: "Регулярное обновление версий \n программного обеспечения до \n актуального состояние" },
        { id: "04", name: "Использование изолированных \n окружений для сайтов клиента" },
        { id: "05", name: "Безлимит по дисковой квоте и \n трафику" },
        { id: "06", name: "Собственный СМС шлюз \n с нелимитированным количеством \n сообщений" },
        { id: "07", name: "Шифрование пользовательских \n данных, хранимых клиентом" },
      ]
    };
  },
};
</script>