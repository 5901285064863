<template>
  <Header />
  <Cases />
  <TitleSection :title="'Регионы нашего \n присутствия'" />
  <geoMap />
  <Footer />
</template>

<script>


import Header from '@/components/header.vue'
import Cases from '@/components/cases.vue'
import TitleSection from '@/components/titlesection.vue'
import geoMap from '@/components/geomap.vue'
import Footer from '@/components/footer.vue'

export default {
  name: 'Home',
  mesg: "Hello",
  components: {
    Header,
    Cases,
    TitleSection,
    geoMap,
    Footer,
  },
}
</script>
