<template lang="pug">
.container
  .nav(:class="{ navsticky : isShowBurger }")
    router-link(:to="'/'") 
      img.nav-logo(src="../assets/icons/logo.svg")
    ul.nav-menu
      li.nav-menu-item(v-for="items in navLinks")
        router-link.nav-menu-link(:key="items" :to="`${items.link}`"  @click="dropdown(items,$event)") {{ items.name }}
        img.burger-arrow(src="../assets/icons/menu-arrow.svg" v-if="items.name === 'Услуги'")
        ul.nav-sub(v-if="isOpen && items.name === 'Услуги'", @mouseleave="openClose")
          li.nav-sub-item(v-for="el in subMenu") 
            router-link.nav-sub-link(:key="el", :to="`${el.link}`") {{ el.name }}
    .burger      
      img.burger-img(src="../assets/icons/burger.svg" @click="isShowBurger = !isShowBurger")
      img.burger-close(src="../assets/icons/burger-close.svg" v-show="isShowBurger" @click="isShowBurger  = !isShowBurger")
      ul.burger-menu(:class="{ activeburger : isShowBurger}")
        li.burger-menu-item(v-for="items in navLinks")
          router-link.burger-menu-link(:key="items" :to="`${items.link}`"  @click="dropdown(items,$event)") {{ items.name }}
          img.burger-sub-arrow(src="../assets/icons/menu-arrow.svg" v-if="items.name === 'Услуги'")
          ul.burger-sub(v-if="isOpen && items.name === 'Услуги'", @mouseleave="openClose")
            li.burger-sub-item(v-for="el in subMenu") 
              router-link.burger-sub-link(:key="el", :to="`${el.link}`") {{ el.name }}
              
</template>

<script>
export default {
  name: "navMenu",
  data() {
    return {
      isOpen: false,
      isShowBurger: false,
      navLinks: [
        { name: "Клиенты", link: "/Clients" },
        { name: "Услуги", link: ""},
        { name: "Компания", link: "/About" },
        { name: "Тех. Поддержка", link: "/Support" },
      ],
      subMenu: [
        { name: "Хостинг/ЦОД", link: "/Hosting" },
        { name: "Веб-сайты", link: "/Cases" },
        { name: "Реклама", link: "/Advertising" },
        { name: "Дизайн-студия", link: "/Design" },
        { name: "CMS и CRM", link: "/CmsAndCrm" },
        { name: "Моб. приложения", link: "/Design" },
        { name: "Пресс релиз", link: "/PressReliz" }
      ],
    };
  },
  methods: {
    dropdown(sub,e) {  
      if (sub.name === "Услуги") {
        e.preventDefault();
        this.isOpen = !this.isOpen;
      }
    },
    openClose() {
      this.isOpen = !this.isOpen;
    },
  },
}
</script>
