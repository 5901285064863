<template lang="pug">
.cases-wrapper
  .case(v-for="item in casesArray.slice(0,`${slidesShow(casesArray, isCasesPage)}`)" 
  :key="item.name" 
  :class="{ lastcase : isCasesPage, smallmaincase : isCasesPage === false }"
  )
    .case-info
      a.case-info-title(:href="`${item.link}`") {{item.name}}
      img.case-arrow(v-if="item.icon" :src="require(`../assets/icons/${item.icon}`)")
    img.case-img(:src="require(`../assets/images/${item.images}`)" )
</template>

<script>
export default {
  name: "Cases",
  props: {
    isCasesPage: {
      type: Boolean,
      default: false,
    },
    slides: {
      type: Number,
      default: 12,
    }
  },
  data() {
    return {
      isHover: false,
      casesArray: [
        {name: "Разработка сайта для Национального парка «Койгородский»", images: "koygorodskii.png", link: "https://koygorodskiy.ru/"},
        {name: "Разработка системы электронного документооборота для «СГМУ»", images: "pk.jpg", link: "https://stdo-demo.1mcg.ru/user/nu-6d52d653cd2e0eca58ce7f46ab3cdfe0/"},
        {name: "Разработка сайта для Кандалакшского государсвтвенного заповедника", images: "kandala.png", link: "https://kandalaksha-reserve.ru/"},
        {name: "Разработка сайта для центра «Пермского государственного хореаграфического училища»", images: "onyx.png", link: "https://balletperm.ru/"},
        {name: "Разработка сайта для международного аэропорта Архангельск", images: "arhaero.png", link: "https://arh.aero"},
        {name: "Разработка сайта для аптечной сети «Фито фарм»", images: "ffarm.png", link: "https://ffarm.ru/"},
        {name: "Разработка сервиса для «ЖКХ»", images: "gku.jpg", link: "https://gkh.1mcg.ru/"},
        {name: "Разработка сайта для «Госюрбюро НАО»", images: "yur.jpg", link: "https://ub.1mcg.ru"},
        {name: "Разработка сайта для завода судостроения «Звёздочка»", images: "star.png", link: "http://star.ru/"},
        {name: "Разработка сайта для инвест портала Кемерово", images: "kemerovo-1.png", link: "http://ip.kemerovo.ru/"},
        {name: "Разработка сайта для магазина одежды «PRIAMO»", images: "sew.png", link: "https://priamo52.ru/"},
        //Всегда должен быть 12-ой позицией!
        {name: "Ещё кейсы", images: "morecase.png", icon: "right-arrow.svg", link: "/Clients"},
        //
        {name: "Разработка сайта для магазина оптики «Мода Оптик»", images: "optic.png", link: "https://moda-optic.ru/"},
        {name: "Разработка сайта для «Архангельское Городское Специализированое Управление Механизации» (АГСУМ)", images: "katok.png", link: "https://agsum.1mcg.ru/"},
        {name: "Разработка сайта для завода судостроения «Средненевский»", images: "baltika.png", link: "https://snsz.ru/"},
        {name: "Разработка сайта для проекта «Sustainability of Architectural Heritage» (SAH) ", images: "sah.png", link: "https://sah.1mcg.ru"},

      ]
    }
  },
  methods: {
    slidesShow(array, iscase) {
      if(iscase) {
        return array.length;
      } else {
        return 12;
      }
    }
  }
}
</script>