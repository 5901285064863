<template lang="pug">
.headerpages
  .headerpages-wrapper
    .headerpages-title {{title}}
    img.headerpages-title-icon(src="../assets/icons/&.svg")
    .headerpages-text {{text}}
</template>

<script>
export default {
name: "HeaderPages",
props: {
    title: String,
    text: String,
  },
}
</script>

