<template>
  <navMenu />
  <HeaderPages :title="'Реклама'" :text="this.text" />
  <Prices
    :prices='this.prices'
    :images='this.images'
  />
  <Footer />
</template>

<script>
import navMenu from "@/components/navmenu.vue";
import HeaderPages from "@/components/headerpages.vue";
import Prices from "@/components/prices.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Advertising",
  components: {
    navMenu,
    HeaderPages,
    Prices,
    Footer,
  },
  data() {
    return {
      images: "advert.png",
      prices: [
        {
          id: "1.svg",
          title: "Настройка \n direct/adwords",
          price: "17 500 руб.",
        },
        {
          id: "2.svg",
          title: "День размещения \n direct/adwords",
          price: "5400 руб.",
        },
      ],
      text: `Для выпускаемых проектов компания оказывает услуги  
        по конфигурации аккаунтов Яндекс Директ и Google  
        AdWords, а также предлагает пакетные размещения в  
        данных системах с фиксированной абонентской платой. 
        Рекламные пакеты могут иметь отраслевые, 
        региональные приорететы, а также приоритеты по   
        количеству показов, что существенно сокращает  
        бюджеты заказчиков. Показ контекстной рекламы 
        происходит через все сервисы Яндекс и Google, а также  
        в сервисах партнеров: Mail, Rambler, Yahoo и т.д.`,
    };
  },
};
</script>