<template>
  <navMenu />
  <HeaderPages
    :title="'Клиенты'"
    :text='this.text'
  />
  <TitleSection :title="'Кейсы'" />
  <Cases :isCasesPage='true' />
  <Footer />
</template>

<script>
import navMenu from '@/components/navmenu.vue'
import HeaderPages from '@/components/headerpages.vue'
import TitleSection from '@/components/titlesection.vue'
import Cases from "@/components/cases.vue"
import Footer from "@/components/footer.vue"


export default {
  name: "Clients",
  components: {
    navMenu,
    HeaderPages,
    TitleSection,
    Cases,
    Footer,
  },
  data() {
    return {
      text: `На протяжении многих лет нашими
        клиентами являются люди, которые не
        боятся изменений и верят в реализацию 
        своих самых амбициозных планов. Наши
        клиенты – это личности, которые не
        останавливаются в своем росте и развитии. 
        Они ставят перед собой смелые задачи, и
        готовы искать способы их реализации`,
    }
  },
}
</script>
