<template>
  <navMenu />
  <HeaderPages :title="'Пресс релиз'" />
  <Services 
            :services='this.services' 
            :isImage='true' 
            :postImages='this.postImages' 
            :nthFlex='true' />
  <Footer />
</template>

<script>
import navMenu from "@/components/navmenu.vue";
import HeaderPages from "@/components/headerpages.vue";
import Services from "@/components/services.vue"
import Footer from "@/components/footer.vue";
export default {
  name: "PressReliz",
  components: {
    navMenu,
    HeaderPages,
    Services,
    Footer,
  },
  data() {
    return {
      postImages: 'cms.png',
      text: `Скарлетт и Монарх: Управление сайтом не 
        требует от Вас специальных технических
        навыков, знаний технологий или 
        программных продуктов, за исключением 
        общих навыков работы с персональным 
        компьютером и веб-браузером*.`,
      subText: `* Следите за актуальностью версии Вашего веб-браузера. 
        Для работы рекомендуем использовать Firefox, Chrome, 
        Opera, Safari, Yandex.`,
      services: [
        { id: "", name: "Преcc релиз", text: `` },
        
      ]
    };
  },
};
</script>