<template>
<navMenu />
<Footer />
  
</template>

<script>
import Footer from "@/components/footer.vue"
import navMenu from "@/components/navmenu.vue"
export default {
  name: "Support",
  components: {
    navMenu,
    Footer,
  },
}
</script>