<template>

<title>Скарлетт и Монарх</title> 
<router-view/>
</template>
<style>

</style>
<script>
import "./assets/scss/index.scss";

export default {};
</script>