<template>
  <navMenu />
  <HeaderPages :title="'Веб-сайты'" :text='this.text' />
  <Services :services='this.services' :hostImg='false' />
  <Footer />
</template>

<script>
import navMenu from "@/components/navmenu.vue";
import HeaderPages from "@/components/headerpages.vue";
import Services from "@/components/services.vue"
import Footer from "@/components/footer.vue";
export default {
  name: "Hosting",
  components: {
    navMenu,
    HeaderPages,
    Services,
    Footer,
  },
  data() {
    return {
      text: `Все продукты компании базируются на ядре CMS
        "Скарлетт и Монарх: Управление сайтом", запись в 
        реестре Минсвязь №1897. Интерфейс панели управления
        реализован с адаптацией для планшетов и мобильных 
        устройств. Двухэтапные и двухфакторные механизмы 
        аутентификации. Разграничение прав доступа
        пользователей панели для файлов и остального
        контента. Основной метод работы с панелью:
        Drag'n'Drop. Гибкая архитектура построения контента.
        Событийная система управления.`,
      services: [
        { id: "01", name: "Интернет-касса", text: `от 89 000 руб.` },
        { id: "02", name: "Landing", text: `от 360 000 руб.` },
        { id: "03", name: "Сайт образовательного \n учреждения", text: `от 175 000 руб.` },
        { id: "04", name: "Сайт администрации", text: `от 775 000 руб.` },
        { id: "05", name: "Интернет-магазин", text: `от 1 200 000 руб.` },
        { id: "06", name: "ИС ТСЖ - УК", text: `от 2 400 000 руб.` },
        { id: "07", name: "СЭДО", text: `от 1 200 000 руб.` },
      ],
      
    };
  },
};
</script>