<template lang="pug">
.services
  .services-wrapper(v-if="nthFlex === false")
    .services-item(v-for="(items, i) in services" :key="services.id")
      .services-item-id {{items.id}}
      .services-item-title {{items.name}}
      .services-item-text(v-if="items.text") {{items.text}}
  .services-wrapper(v-if="nthFlex")
    .services-col-one
      .services-col-item(v-for="(items, i) in services.slice(0,3)" :key="services.id")
        .services-item-id {{items.id}}
        .services-item-title {{items.name}}
        .services-item-text(v-if="items.text") {{items.text}}
    .services-col-two
      .services-col-item(v-for="(items, i) in services.slice(3,6)" :key="services.id")
        .services-item-id {{items.id}}
        .services-item-title {{items.name}}
        .services-item-text(v-if="items.text") {{items.text}}
  img.services-host(src="../assets/images/host-server.png" :class="{ hostimg : hostImg }")
  img.services-image(:src="require(`../assets/images/${postImages}`)" :class="{ postimage : isImage}" )
</template>

<script>
export default {
  name: "Services",
  props: {
    services: Array,
    postImages: {
      type: String,
      default: 'hosting.png',
    },
    hostImg: {
      type: Boolean,
      default: false,
    },
    isImage: {
      type: Boolean,
      default: false,
    },
    nthFlex: {
      type: Boolean,
      default: false,
    }
  },
}
</script>
