<template lang="pug">
.section
  .section-info
    .section-title {{title}}
    img.section-title-icon(src="../assets/icons/&.svg")
    img.section-nav-icon(src="../assets/icons/srolldownred.svg")
</template>
<script>
export default {
  name: "TitleSection",
  props: {
    title: String,
  },
  data() {
    return {
      
    }
  }, 
}
</script>
