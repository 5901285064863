import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Support from '../views/Support.vue'
import Clients from '../views/Clients.vue'
import Hosting from '../views/Hosting.vue'
import Design from '../views/Design.vue'
import Advertising from '../views/Advertising.vue'
import CmsAndCrm from '../views/Cmsncrm.vue'
import CasesPage from '../views/CasesPage.vue'
import PressReliz from '../views/PressReliz.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Support',
    name: 'Support',
    component: Support
  },
  {
    path: '/Hosting',
    name: 'Hosting',
    component: Hosting   
  },
  {
    path: '/PressReliz',
    name: 'PressReliz',
    component: PressReliz   
  },
  {
    path: '/Advertising',
    name: 'Advertising',
    component: Advertising
  },
  {
    path: '/Clients',
    name: 'Clients',
    component: Clients
  },
  {
    path: '/Design',
    name: 'Design',
    component: Design
  },
  {
    path: '/CmsAndCrm',
    name: 'CmsAndCrm',
    component: CmsAndCrm
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/Cases',
    name: 'Cases',
    component: CasesPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
