<template lang="pug">
.footer
  .footer-contacts-main
    .footer-contacts-main-wrapper(v-for="contactsMain in mainContacts" :key="contactsMain.title")
      .footer-contacts-main-subtitle {{ contactsMain.title }}
      .footer-contacts-main-title {{ contactsMain.contact }}
  .footer-contacts-sub
    .footer-contacts-sub-wrapper(v-for="contactsSub in subContacts" :key="contactsSub.title")
      .footer-contacts-sub-title {{ contactsSub.title }}
      .footer-contacts-sub-contact {{ contactsSub.contact }}
        //- router-link.footer-contacts-socials(v-for="social in contactsSub.socials" :key="social.name" :to="`${social.link}`")
        //-   img.footer-contacts-socials-img(:src="require(`../assets/icons/${social.icon}`)" :alt="`${social.name}`")

       
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      mainContacts: [
        { title: "Напишите нам", contact: "commerce@websm.io" },
        { title: "Звонок по России бесплатный", contact: "8-800-200-27-77" },
      ],
      subContacts: [
        { title: "Техническая поддержка", contact: "support@websm.io"},
        { title: "Отдел разработки", contact: "developer@websm.io \n  \n "},
        { title: "Адрес", contact: "163060, г. Архангельск,  \n А/Я 15"},
        { title: "", contact: ""},
        { socials: [
          { name: "Facebook", icon: "facebook.svg", link: "#" },
          { name: "Instagram", icon: "instagram.svg", link: "#" },
          { name: "Vk", icon: "vk.svg", link: "#" },
          { name: "Twitter", icon: "twitter.svg", link: "#" },
          { name: "Youtube", icon: "youtube.svg", link: "#" },
        ]}
      ]
    }
  },
}
</script>
