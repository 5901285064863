<template lang="pug">
.prices( :class="{ blackbg : blackBg }")
  .prices-wrapper( :class="{ columnview : columnView }")
    .prices-item(v-for="item in prices" :key="item")
      img.prices-item-id(:src="require(`../assets/icons/${item.id}`)")
      .prices-item-title {{item.title}}
      .prices-item-subtitle Стоимость проекта
      .prices-item-price {{item.price}}
    .prices-design( :class="{ designicon : designIcon }")
      img.prices-design-white(src="../assets/icons/design-white.svg")
      img.prices-design-black(src="../assets/icons/design-black.svg")
  img.prices-image(:src="require(`../assets/images/${images}`)" )
</template>
<script>
export default {
  name: "Prices",
  props: {
    prices: Array,
    images: {
      type: String,
      default: 'hosting.png'
    },
    designIcon: {
      type: Boolean,
      default: false,
    },
    columnView: {
      type: Boolean,
      default: false,
    },
    blackBg: {
      type: Boolean,
      default: false,
    },
  },
  
}
</script>
