<template>
  <navMenu />
  <HeaderPages :title="'Дизайн-студия'" :text="this.text" />
  <Prices
    :prices='this.prices'
    :images='this.images'
    :designIcon='true'
    :columnView='true'
  />
  <Footer />
</template>

<script>
import navMenu from "@/components/navmenu.vue";
import HeaderPages from "@/components/headerpages.vue";
import Prices from "@/components/prices.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Design",
  components: {
    navMenu,
    HeaderPages,
    Prices,
    Footer,
  },
  data() {
    return {
      images: "design.png",
      prices: [
        {
          id: "1.svg",
          title: "Макет + \n мобильная версия",
          price: "255 000 руб.",
        },
        {
          id: "2.svg",
          title: "Разработка \n логотипа",
          price: "310 000 руб.",
        },
        {
          id: "3.svg",
          title: "Разработка \n брендбука",
          price: "570 000 руб.",
        },
      ],
      text: "Разработка макетов главной и внутренних страниц \n web-сайтов (от сайта-визитки до интернет-портала). \n Индивидуальный и адаптивный дизайн (версия для ПК \n любых расширений, планшетная и мобильная версии), \n соответствующий фирменному стилю организации, \n индивидуальный дизайн для каждого проекта. Виды \n графического дизайна: создание логотипа, рестайлинг и  \n векторизация (отрисовка) логотипа; дизайн визитки, \n календарей, баннера для наружной рекламы (билборд); \n разработка брендбука.",
    };
  },
};
</script>