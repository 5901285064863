<template>
  <navMenu />
  <HeaderPages :title="'CMS и CRM'" />
  <Services 
            :services='this.services' 
            :isImage='true' 
            :postImages='this.postImages' 
            :nthFlex='true' />
  <Footer />
</template>

<script>
import navMenu from "@/components/navmenu.vue";
import HeaderPages from "@/components/headerpages.vue";
import Services from "@/components/services.vue"
import Footer from "@/components/footer.vue";
export default {
  name: "Hosting",
  components: {
    navMenu,
    HeaderPages,
    Services,
    Footer,
  },
  data() {
    return {
      postImages: 'cms.png',
      text: `Скарлетт и Монарх: Управление сайтом не 
        требует от Вас специальных технических
        навыков, знаний технологий или 
        программных продуктов, за исключением 
        общих навыков работы с персональным 
        компьютером и веб-браузером*.`,
      subText: `* Следите за актуальностью версии Вашего веб-браузера. 
        Для работы рекомендуем использовать Firefox, Chrome, 
        Opera, Safari, Yandex.`,
      services: [
        { id: "01", name: "Преимущества", text: `- Управление сайтом эмулирует работу с обычным 
                                          текстовым редактором

                                          - Панель управления оптимизирована под любое 
                                          устройство.

                                          - Реализована возможность многозадачной работы с 
                                          модулями.

                                          - Файловый менеджер системы Скарлетт и Монарх: 
                                          Управление сайтом обладает мультиформатностью - Вы 
                                          можете загрузить на сайт любой документ.

                                          - Со временем в панели может накопиться большое 
                                          количество документов, файловый менеджер имеет 
                                          удобную сортировку.

                                          - Возможна одновременная загрузка нескольких файлов.

                                          - Перед публикацией изображения доступен предпросмотр.

                                          - Благодаря парсеру, обрабатывающему документы word, 
                                          Вы можете загружать документы, с сохранением 
                                          форматирования и изображений и публиковать их в виде 
                                          страниц.

                                          - Поддерживается функция drag and drop (в переводе с 
                                          английского буквально означает тащи-и-бросай) для 
                                          сортировок вывода страниц и файлового менеджера сайта. 
                                          Способ реализуется путём «захвата» (нажатием и 
                                          удержанием кнопки мыши) объекта, программно доступного 
                                          для подобной операции, и перемещения его в другое место.

                                          - Система Скарлетт и Монарх: Управление сайтом 
                                          поддерживает обмен данными с 1С и любым другим 
                                          программным обеспечением (за исключением некоторых 
                                          закрытых форматов) заказчика для автоматической 
                                          публикации в интернет-магазине каталогов товаров, 
                                          прайс-листов, выгрузки заказов.` },
         { id: "02", name: "Производительность", text: `Высокая производительность системы Скарлетт и Монарх: 
                                                  Управление сайтом обеспечивается рядом встроенных 
                                                  параметров: файловый менеджер панели управления 
                                                  оснащен функцией автоматического сжатия изображений 
                                                  до интернет стандартов; выполняется кэширование 
                                                  запросов на стороне браузера клиента; минимизируются 
                                                  рекурсивные запросы при обращении к большим объемам 
                                                  данных, файловый менеджер содержит собственную 
                                                  виртуальную файловую систему.

                                                  Использование протокола http2 значительно ускоряет 
                                                  загрузку ресурса.`},
        { id: "03", name: "Мобильность", text: `Благодаря адаптивной верстке Ваш сайт, разработанный на 
                                          Скарлетт и Монарх: Управление сайтом, изначально готов к 
                                          просмотру на любом устройстве.` },                                          
        { id: "04", name: "Разнообразность", text: `Скарлетт и Монарх: Управление сайтом - это большое 
                                              количество программных модулей для решения задач 
                                              любой сложности от размещения текста до приема 
                                              платежей. Количество программных модулей, 
                                              интегрированных в систему, Вы определяете 
                                              самостоятельно при заполнении технического задания.

                                              Мы не привязываем Вас к каталогу готовых решений, когда 
                                              один из необходимых программных модулей может 
                                              оказаться в более дорогой версии программы.

                                              В базу уже включено разграничение прав доступа - 
                                              администратор сайта может создавать редакторов и 
                                              назначать им права на работу с определенными 
                                              программными модулями и разделами и выполнение 
                                              определенных действий.` },
       
        { id: "05", name: "Безопасность", text: `- Панель управления поддерживает односессионную работу 
                                            в учетной записи, т.е. одновременно два пользователя не 
                                            могут находиться в панели под одним логином и паролем.

                                            - Файловый менеджер имеет антивирусную проверку 
                                            загружаемых файлов.

                                            - Двух этапная система аутентификации с использованием 
                                            одноразовых паролей для администратора, редактора при 
                                            входе на панель управления, для зарегестрированного 
                                            пользователя при входе в личный кабинет. После ввода 
                                            пароля, на мобильный телефон поступит ussd - сообщение с 
                                            одноразовым кодом доступа. Не требует от Вас и Ваших 
                                            клиентов приобретения дополнительного программного 
                                            обеспечения или аппаратного устройства (например, 
                                            Alladdin eToken PASS).

                                            - Ограничение сессионного времени обуславливается 
                                            необходимостью безопасной работы конечного 
                                            пользователя с панелью управления. Время сессии может 
                                            составлять от 10 до 30 минут.

                                            - Защита от подбора пароля - при неправильном вводе 
                                            логина или пароля происходит блокировка на промежуток 
                                            времени, который возрастает в геометрической прогрессии 
                                            при повторении ошибки. Снять блокировку может только 
                                            администратор, редактор, введя одноразовый код доступа.

                                            - В журнале работы с панелью ведется протоколирование 
                                            случаев входа в панель управления с сохранением даты, 
                                            времени и IP адреса, а также фиксируются попытки взлома 
                                            страниц, с описанием вида атаки и IP адреса злоумышленника.

                                            - Резервное копирование сайта.

                                            - Скарлетт и Монарх: Управление сайтом имеет систему 
                                            шифрования данных, которая базируется на 
                                            криптоалгоритмах AES с уникальным ключом шифрования 
                                            для каждого ресурса.

                                            - Используется шифрование трафика по протоколу TLS 1.2

                                            - Защита от DDoS обеспечивается Firewall, который 
                                            ограничивает количество запросов по IP адресам и 
                                            подсетям.

                                            - Для нас безопасность любого веб-проекта независимо от 
                                            его вида, будь это сайт детского сада или интернет-магазин, 
                                            стоит на первом месте. Поэтому мы не делим безопасность 
                                            на программные модули. Любой веб-проет, работающий под 
                                            управлением Скарлетт и Монарх: Управление сайтом, имеет 
                                            все уровни защиты.` },
        
      ]
    };
  },
};
</script>